import React, {Component} from 'react'
import toggleShow from "../../scripts/button";
import "./Projs.css"

class ProjHeading extends Component {
    render() {
        return (
            <div className={"heading"}>
                <h1 className={"project-title"}>{this.props.title}</h1>
                <button className={"desc-button"} onClick={() => toggleShow(this.props.descriptionId)}>Description</button>
                {/*<hr/>*/}
                <div className={"description"} id={this.props.descriptionId}>
                    {this.props.description}
                </div>
                {/*<hr/>*/}
            </div>
        )
    }
}

export default ProjHeading