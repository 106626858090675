import React, {Component} from "react";
import "./Link.css"

class Link extends Component {
    render() {
        return (
            <a className={"click-me"} href={this.props.url} target={this.props.target}>{this.props.text}</a>
        )
    }
}

export default Link