import React, {Component} from 'react'
import getApi from "../../../scripts/apis";
import toFloat from "../../../scripts/types";

class BasketballForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            year: 2021,
            scoreConfig: {
                min: 1.0,
                fgm: 1.0,
                fga: 1.0,
                fgp: 1.0,
                fpp: 1.0,
                ftm: 1.0,
                fta: 1.0,
                ftp: 1.0,
                tpm: 1.0,
                tpa: 1.0,
                tpp: 1.0,
                reb: 1.0,
                ass: 1.0,
                stl: 1.0,
                bks: 1.0,
                tvs: 1.0,
                dds: 1.0,
                pts: 1.0
            },
            basketballApi: getApi(process.env.REACT_APP_BASKETBALL_URL)
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        let val = event.target.value;
        this.setState({scoreConfig: {...this.state.scoreConfig, [event.target.name]: toFloat(val)}});
        console.log(this.state)
    }

    selectYear(event) {
        this.setState({year: event.target.value});
    }

    async handleSubmit(event) {
        event.preventDefault();
        try {
            const res = await this.state.basketballApi.post("/ratings/" + this.state.year, this.state.scoreConfig);
            console.log("data", res.data);
            this.props.updateTeam(res.data.Team);
            this.props.updatePlayers(res.data.Players);
        }catch (e) {
            console.error(e);
        }
    }

    render() {
        return (
            <div id={"basketball-form"}>
                <form id={"score-config-form"} onSubmit={this.handleSubmit}>
                    2019<input id={"2019"} name={"year"} type="radio" value={"2019"} onChange={this.selectYear.bind(this)}/>
                    2020<input id={"2020"} name={"year"} type="radio" value={"2020"} onChange={(event) => {this.setState({scoreConfig: {...this.state, "year": event.target.value}})}}/>
                    2021<input id={"2021"} name={"year"} type="radio" value={"2021"} onChange={(event) => {this.setState({scoreConfig: {...this.state, "year": event.target.value}})}}/>
                    <BasketballInput field={"min"} name={"Minutes"} value={this.state.scoreConfig.min} handleChange={this.handleChange.bind(this)} autofocus/>
                    <BasketballInput field={"fgm"} name={"Field Goals Made"} value={this.state.scoreConfig.fgm} handleChange={this.handleChange.bind(this)}/>
                    <BasketballInput field={"fga"} name={"Field Goals Attempted"} value={this.state.scoreConfig.fga} handleChange={this.handleChange.bind(this)}/>
                    <BasketballInput field={"fgp"} name={"Field Goal Percentage"} value={this.state.scoreConfig.fgp} handleChange={this.handleChange.bind(this)}/>
                    <BasketballInput field={"ftm"} name={"Free Throws Made"} value={this.state.scoreConfig.ftm} handleChange={this.handleChange.bind(this)}/>
                    <BasketballInput field={"fta"} name={"Free Throws Attempted"} value={this.state.scoreConfig.fta} handleChange={this.handleChange.bind(this)}/>
                    <BasketballInput field={"ftp"} name={"Field Throws Percentage"} value={this.state.scoreConfig.ftp} handleChange={this.handleChange.bind(this)}/>
                    <BasketballInput field={"tpm"} name={"Three Pointers Made"} value={this.state.scoreConfig.tpm} handleChange={this.handleChange.bind(this)}/>
                    <BasketballInput field={"tpa"} name={"Three Pointers Attempted"} value={this.state.scoreConfig.tpa} handleChange={this.handleChange.bind(this)}/>
                    <BasketballInput field={"tpp"} name={"Three Pointers Percentage"} value={this.state.scoreConfig.tpp} handleChange={this.handleChange.bind(this)}/>
                    <BasketballInput field={"reb"} name={"Rebounds"} value={this.state.scoreConfig.reb} handleChange={this.handleChange.bind(this)}/>
                    <BasketballInput field={"ass"} name={"Assists"} value={this.state.scoreConfig.ass} handleChange={this.handleChange.bind(this)}/>
                    <BasketballInput field={"stl"} name={"Steals"} value={this.state.scoreConfig.stl} handleChange={this.handleChange.bind(this)}/>
                    <BasketballInput field={"bks"} name={"Blocks"} value={this.state.scoreConfig.bks} handleChange={this.handleChange.bind(this)}/>
                    <BasketballInput field={"tvs"} name={"Turnovers"} value={this.state.scoreConfig.tvs} handleChange={this.handleChange.bind(this)}/>
                    <BasketballInput field={"dds"} name={"Double Doubles"} value={this.state.scoreConfig.dds} handleChange={this.handleChange.bind(this)}/>
                    <BasketballInput field={"pts"} name={"Points"} value={this.state.scoreConfig.pts} handleChange={this.handleChange.bind(this)}/>
                </form>
                <button type="submit" form="score-config-form" value="Submit">Shoot your shot!</button>
            </div>
        )
    }
}

class BasketballInput extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.handleChange(event);
    }

    render() {
        return (
            <p>
                <label htmlFor={this.props.field}>{this.props.name} Multiplier</label>
                <input id={this.props.field} name={this.props.field} type="number" min="0" max="10" step="0.01" value={this.props.value} onChange={this.handleChange.bind(this)}/>
            </p>
        )
    }
}

export default BasketballForm