import React, {Component} from 'react'
import kobe from "../../../img/kobe.jpg"

class Kobe extends Component {
    render() {
        return (
            <div id="kobe-pic">
                <img src={kobe} alt={"24"}/>
            </div>
        )
    }
}

export default Kobe