import React, {Component} from 'react'
import basketballImg from "../../../img/basketball.png"

class BasketballResults extends Component {
    render() {
        return (
            <div id="results">
                <span className="bb-results-span">Suggested Team</span>
                <hr/>
                {this.props.team.map((player, i) => {
                    return (<PlayerInfo player={player} key={i}/>)
                })}
                <span className="bb-results-span">All Players</span>
                <hr/>
                {this.props.players.map((player, i) => {
                    return (<PlayerInfo player={player} key={i}/>)
                })}
            </div>
        )
    }
}


class PlayerInfo extends Component {
    render() {
        return (
            <div className={"basketball-player-div"}>
                <img src={basketballImg} className={"basketball-player-info"} alt={"face"}/>
                <span className={"basketball-player-info"}>{this.props.player.Name}</span>
                <span className={"basketball-player-info"}>{this.props.player.Position}</span>
                <span className={"basketball-player-info"}>{this.props.player.Score}</span>
            </div>
        )
    }
}

export default BasketballResults