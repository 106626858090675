import axios from 'axios';

const apis = {};

export default function getApi(host){
    if (apis[host]){
        return apis[host]
    }else{
        const instance = axios.create({
            baseURL: host,
            timeout: 1000,
            headers: {'Content-Type': 'application/json'},
            responseType: "json"
        });
        apis[host] = instance;
        return instance;
    }
}
