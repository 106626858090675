import React from 'react';
import './App.css';
import Nav from "./components/Nav"

function App() {
  return (
    <div className="App">
      <header>
          sheldonsandbox
      </header>
        <Nav/>
    </div>
  );
}

export default App;
