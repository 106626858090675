import React, { Component } from 'react'
import "./Page.css"
class Page extends Component {
    render() {
        return (
            <div className={"container"}>
                {this.props.content}
            </div>
        )
    }
}
export default Page