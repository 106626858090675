import React, {Component} from 'react'
import BasketballDescription from "../basketball/BasketballDescription"
import BasketballResults from "./BasketballResults";
import BasketballForm from "./BasketballForm";
import Kobe from "./Kobe";
import ProjHeading from "../ProjHeading";
import "../Projs.css"

class Basketball extends Component {
    constructor(props) {
        super(props);
        this.state = {
            team: [],
            players: [],
            show: false
        };

        this.updateTeam.bind(this);
        this.updatePlayers.bind(this);
    }

    updateTeam(team) {
        this.setState({team: team, show: true});
    }

    updatePlayers(players) {
        this.setState({players: players});
    }

    render() {
        return (
            <div className={"content"}>
                <ProjHeading title={"KOBE"} descriptionId={"basketball-description"} description={<BasketballDescription/>}/>
                <div className={"project"} id={this.props.id}>
                    <BasketballForm updateTeam={this.updateTeam.bind(this)}
                                    updatePlayers={this.updatePlayers.bind(this)}/>
                    {this.state.show && <Kobe/>}
                    {this.state.show && <BasketballResults team={this.state.team} players={this.state.players}/>}
                </div>
            </div>
        )
    }
}

export default Basketball