import React, {Component} from "react";
import Link from "../../Link";

class BasketballDescription extends Component{
    render() {
        return (
            <pre>
                {`In Fantasy Basketball (`}<Link url={"https://www.reddit.com/r/nba/comments/1pv3h0/eli5_fantasy_basketball/"} target={"_blank"} text={"ELI5"}/>{`) there are a wide variety of scoring systems.
To keep things simple, let's say the only stats that matter are Points, Rebounds, and Assists and each team has two players.
Imagine Team A has Jayson Tatum and Gordon Hayward and Team B has Jaylen Brown and Marcus Smart.
Using stats from a random `}<Link url={"https://www.espn.com/nba/boxscore?gameId=401161374"} target={"_blank"} text={"game"}/>{` we'll be able to understand two common ways to score Fantasy Basketball.

First, Category Points: a point is 1 point, a rebound is 1.2 points, and an assist is 1.5 points (example scale).

Team A: Tatum's 31.6 points (25 * 1 + 3 * 1.2 + 4 * 1.5) + Smart's points 26.8 (16 * 1 + 4 * 1.2 + 4 * 1.5) = 58.4
Team B: Brown's 44.3 points (32 * 1 + 9 * 1.2 + 1 * 1.5) + Hayward's 17.8 points (4 * 1 + 9 * 1.2 + 2 * 1.5) = 62.1

In this system Team B would beat Team A.

Second, Category Wins: most points wins points, most rebounds wins rebounds, most assists wins assists.

Team A: Tatum's 25 points, 3 rebounds, and 4 assists + Smart's 16 points, 4 rebounds, and 4 assists = 41 points, 7 rebounds, and 8 assists
Team B: Brown's 32 points, 9 rebounds, and 1 assist + Hayward's 4 points, 9 rebounds, and 2 assists = 36 points, 18 rebounds, and 3 assists

In this system Team A would beat Team B.

How a group decides their system doesn't matter much as long as everyone understands what's going on.
One thing to note though is with the first system is a manager has a simpler time comparing players.
Imagine how things become more complex when there are more categories, teams, and players per team.
"Shopping" for free agents and proposing/analyzing trade requests can be much harder with the second scoring system.
One could use an online point system, but that might have categories not involved in your leagues settings or an unwanted point scale.

This tool I made for my friend, the Fantasy Basketball Legend, Horace Earl, helps ease those difficulties.
** There are far more stats available, but these are the ones we use for our league. I may add others in the future **
Pick which categories matter and by how much and then you've got a list of players (who've played at least 15 games) ranked by your own scale.
Looking for more Three's without sacrificing Field Goal Percentage and Turnovers going into your next matchup? Slide the dials.
Don't care about Steals and Blocks (`}<Link url={"https://www.youtube.com/watch?v=KUdP7WtDsCA"} target={"_blank"} text={"BAKA!"}/>{`)? Slide the dials.
The resulting points don't actually matter, but the player ranks and gaps between players matter do.
The resulting team is based on a roster of eleven players with the following positions and caps
PG (3), SG (3), SF (3), PF (3), C  (2), G, F, UTIL, BENCH, BENCH, BENCH`}
            </pre>
        )
    }
}

export default BasketballDescription