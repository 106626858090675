import React, {Component} from 'react'
import Slider from 'infinite-react-carousel';
import Page from "./Page";
import Basketball from "./projs/basketball/Basketball";

class Nav extends Component {
    render() {
        return (
            <Slider dots adaptiveHeight={true}>
		{/*
                <div>
                    <Page content={<Basketball/>}/>
                </div>
		*/}
                <div>
                    <Page content={"more to come - recent married with our first child on the way; won't be tinkering as much right now."}/>
                </div>
            </Slider>
        )
    }
}

export default Nav
